export default defineI18nConfig(() => ({
    legacy: false,
    locale: 'en',
    dateTimeFormats: {
        short: {
            year: 'numeric', month: 'short', day: 'numeric'
        },
        long: {
            year: 'numeric', month: 'short', day: 'numeric',
            weekday: 'short', hour: 'numeric', minute: 'numeric'
        }
    },
    numberFormats: {
        en: {
            currency: {
                style: 'currency',
                currency: 'USD'
            }
        },
        BRL : {
            currency: {
                style: 'currency',
                currency: 'BRL'
            }
        }
    }
}))